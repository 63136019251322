

h1, body {
  color:#09d3ac;
  /* opacity: 0.08; */
}
div#root h1, div#root h2 { 
  text-align: center;
  margin-bottom: 5px;
}

div#root h2 { 
  margin-bottom: 15px;
}

.App {
  /* text-align: center; */
  line-height: 1.4em;
  margin: 30px 20px;

}
.claw-image {
  background-image: url("../public/images/cats2.png");
  background-size: 250px;
}
.hearts-image {
  background-image: url("../public/images/hearts.png");
  background-size: 450px;
}
.full-height {
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-image, .cat-image {
  width: 100%;
}

.main-image {
  margin-top: 5px;
}

.cat-image {
  margin-top: 30px;
}

.image-frame {
  outline: whitesmoke solid 6px;
  /* border-bottom-width: 10px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
}

.fadeInOutAnimation {
  animation: fadeInOut infinite 1s linear alternate-reverse;
}

.doof-pan{
  margin-top: 30px;
  width: 150px;
  border-radius: 100%;
}
ul.list {
  list-style: disc;
  margin-left: 1em;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0% { opacity: 0.5; }
  100% { opacity: 1; }
}
